import Vue from 'vue'
import BaseModule from '../base'
import { escapeRegExp } from '../../../helpers/escapeRegExp'
import { mergeDeep } from '../../../helpers/mergeDeep'
import FakeAnswersModule from '../reusable/fake-answers'
import { copyObject } from '../../../helpers/copyObject'
import { romanDigit } from '../../../helpers/stringUtils'
import Style from '../../../store/models/Style'
import Activity from '../../models/Activity'
import * as numberFormats from '../../helpers/documentHelpers'

export default class WorksheetModule extends BaseModule {
    state() {
        return {
            ...super.state(),
            worksheet: {
                body: {
                    style: new Style(),
                    items: [],
                },
                images: [],
                inline_images: [],
            },
        }
    }

    getters() {
        return {
            ...super.getters(),
            worksheetImages: (state) => state.worksheet.images ?? [],
            worksheetItems: (state) => state.worksheet.body.items,
            worksheetFonts: (state) => {
                return {
                    body_font: state.worksheet.body.style.font,
                    word_bank_font: state.worksheet.body.wordbank?.style?.font,
                }
            },
            worksheetStyle: (state) => state.worksheet.body.style,
            worksheetSetting: (state) => state.worksheet.body.setting,
            numbersAreVisible: (state, getters) => {
                const style = getters.worksheetStyle
                return style.number_format != '4'
            },
            format_number:
                (state, getters) =>
                (digit, format = undefined) => {
                    const style = getters.worksheetStyle
                    format = format ? format : style.number_format

                    switch (format) {
                        case numberFormats.NUMBER_FORMAT_HIDDEN:
                            return ''

                        case numberFormats.NUMBER_FORMAT_DOT:
                            return digit + '.'

                        case numberFormats.NUMBER_FORMAT_NORMAL:
                            return digit

                        case numberFormats.NUMBER_FORMAT_BRACKET:
                            return digit + ')'

                        case numberFormats.NUMBER_FORMAT_COLON:
                            return digit + ':'

                        // LETTER UPPERCASE
                        case numberFormats.NUMBER_FORMAT_LETTER_CAPS_DOT:
                            return (digit + 9).toString(36).toUpperCase() + '.'

                        case numberFormats.NUMBER_FORMAT_LETTER_CAPS:
                            return (digit + 9).toString(36).toUpperCase()

                        case numberFormats.NUMBER_FORMAT_LETTER_CAPS_BRACKET:
                            return (digit + 9).toString(36).toUpperCase() + ')'

                        case numberFormats.NUMBER_FORMAT_LETTER_CAPS_COLON:
                            return (digit + 9).toString(36).toUpperCase() + ':'

                        // LETTER LOWERCASE
                        case numberFormats.NUMBER_FORMAT_LETTER_LOWER_DOT:
                            return (digit + 9).toString(36).toLowerCase() + '.'

                        case numberFormats.NUMBER_FORMAT_LETTER_LOWER:
                            return (digit + 9).toString(36).toLowerCase()

                        case numberFormats.NUMBER_FORMAT_LETTER_LOWER_BRACKET:
                            return (digit + 9).toString(36).toLowerCase() + ')'

                        case numberFormats.NUMBER_FORMAT_LETTER_LOWER_COLON:
                            return (digit + 9).toString(36).toLowerCase() + ':'

                        //  ROMAN UPPERCASE
                        case numberFormats.NUMBER_FORMAT_ROMAN_CAPS_DOT:
                            return romanDigit(digit).toString(36).toUpperCase() + '.'

                        case numberFormats.NUMBER_FORMAT_ROMAN_CAPS:
                            return romanDigit(digit).toString(36).toUpperCase()

                        case numberFormats.NUMBER_FORMAT_ROMAN_CAPS_BRACKET:
                            return romanDigit(digit).toString(36).toUpperCase() + ')'

                        case numberFormats.NUMBER_FORMAT_ROMAN_CAPS_COLON:
                            return romanDigit(digit).toString(36).toUpperCase() + ':'

                        // ROMAN LOWERCASE
                        case numberFormats.NUMBER_FORMAT_ROMAN_LOWER_DOT:
                            return romanDigit(digit).toString(36).toLowerCase() + '.'

                        case numberFormats.NUMBER_FORMAT_ROMAN_LOWER:
                            return romanDigit(digit).toString(36).toLowerCase()

                        case numberFormats.NUMBER_FORMAT_ROMAN_LOWER_BRACKET:
                            return romanDigit(digit).toString(36).toLowerCase() + ')'

                        case numberFormats.NUMBER_FORMAT_ROMAN_LOWER_COLON:
                            return romanDigit(digit).toString(36).toLowerCase() + ':'

                        // SHAPES
                        case numberFormats.NUMBER_FORMAT_SHAPE_STAR:
                            return { name: 'star', icon: 'Star' }

                        case numberFormats.NUMBER_FORMAT_SHAPE_HEART:
                            return { name: 'heart', icon: 'Heart' }

                        case numberFormats.NUMBER_FORMAT_SHAPE_MOON:
                            return { name: 'moon', icon: 'Moon' }

                        case numberFormats.NUMBER_FORMAT_SHAPE_SUN:
                            return { name: 'sun', icon: 'Sun' }

                        case numberFormats.NUMBER_FORMAT_SHAPE_SMILE:
                            return { name: 'emoji-smile', icon: 'Smile' }

                        case numberFormats.NUMBER_FORMAT_SHAPE_DIAMOND:
                            return { name: 'gem', icon: 'Gem' }

                        case numberFormats.NUMBER_FORMAT_SHAPE_CIRCLE:
                            return { name: 'circle', icon: 'Circle' }

                        case numberFormats.NUMBER_FORMAT_BULLET:
                            return { name: 'circleFill', icon: 'CircleFill' }

                        case numberFormats.NUMBER_FORMAT_RADIO:
                            return { name: 'circleRecord', icon: 'CircleRecord' }

                        case numberFormats.NUMBER_FORMAT_SQUARE:
                            return { name: 'square', icon: 'Square' }

                        default:
                            return digit + ' '
                    }
                },
            worksheetWordbank: (state) => state.worksheet.body.wordbank,
            worksheetWordbankStyle: (state) => state.worksheet.body?.wordbank?.style,
            fillInTheBlankGetWords: (state, getters) => {
                //get the terms
                const worksheetItems = getters.worksheetItems
                const setting = getters.worksheetSetting
                let terms = getters.fillInTheBlankGetTerms()
                let words = []

                for (let i = 0; i < worksheetItems.length; i++) {
                    let def = worksheetItems[i].definition ? worksheetItems[i].definition : ''

                    for (let i2 = 0; i2 < terms[i].length; i2++) {
                        if (terms[i][i2].trim()) {
                            //ignore blank terms
                            //find the word at the beginning
                            if (setting.partial_words) {
                                let re = new RegExp(escapeRegExp(terms[i][i2]), 'gi')
                                def = def.replace(re, '〶' + terms[i][i2] + '〶')
                            } else {
                                let re = new RegExp('\\b' + escapeRegExp(terms[i][i2]) + '\\b', 'gi')
                                def = def.replace(re, '〶' + terms[i][i2] + '〶')
                            }
                        }
                    }

                    words.push({
                        terms: terms[i],
                        definition: def ? def.split('〶') : [],
                    })
                }

                return words
            },
            fillInTheBlankGetTerms: (state, getters) => (wordBank) => {
                const worksheetItems = getters.worksheetItems
                let terms = []

                for (let i = 0; i < worksheetItems.length; i++) {
                    let items = worksheetItems[i].term ? worksheetItems[i].term.split('\n') : []

                    if (wordBank) {
                        // this is for the wordbank
                        for (let i2 = 0; i2 < items.length; i2++) {
                            if (items[i2].trim()) {
                                //do not allow blank
                                terms.push({ term: items[i2] })
                            }
                        }
                    } else {
                        terms[i] = items
                    }
                }

                return terms
            },
            canBeShuffled: () => (word) => {
                let uniqueCharacters = String.prototype.concat(...new Set(word))

                return uniqueCharacters.length >= 2
            },
            scramble: (state, getters) => (word, seed) => {
                if (!word) return

                if (!seed) {
                    seed = 1
                }

                if (!getters.canBeShuffled(word)) {
                    // can't scramble a short word!
                    return word.toLowerCase().replace(/ /g, '_')
                }

                var newWord = word
                do {
                    let c = new Chance(state.worksheet.body.shuffle_seed + seed)
                    var letters = word.split('')
                    newWord = c.shuffle(letters).join('')
                    seed++
                } while (newWord == word)

                return newWord.toLowerCase().replace(/ /g, '_')
            },
            worksheetInlineImages: (state) => state.worksheet.inline_images ?? [],
            worksheetAllImages: (state) => [...(state.worksheet.inline_images ?? []), ...(state.worksheet.images ?? [])],
        }
    }

    actions() {
        return {
            ...super.actions(),
            setWorksheet({ commit }, worksheet) {
                commit('SET_WORKSHEET_VALUES', worksheet)
            },
            setWorksheetStyle({ commit }, style) {
                commit('SET_WORKSHEET_STYLE', style)
            },
            setWorksheetBody({ commit }, body) {
                commit('SET_WORKSHEET_BODY', body)
            },
            setWorksheetWordbank({ commit }, wordbank) {
                commit('SET_WORKSHEET_WORDBANK_VALUES', wordbank)
            },
            setWorksheetWordbankStyle({ commit }, style) {
                commit('SET_WORKSHEET_WORDBANK_STYLE', style)
            },
            setWorksheetSetting({ commit }, setting) {
                commit('SET_WORKSHEET_SETTING', setting)
            },
            setCorrectMultipleChoiceOption({ commit, state }, record) {
                let itemCopy = copyObject(state.worksheet.body.items[record.index])
                itemCopy.options[record.optionIndex].correct = record.correct
                commit('UPDATE_ITEM', { index: record.index, item: itemCopy })
            },
            sortMultipleChoiceOptions({ commit, state }, record) {
                let itemCopy = copyObject(state.worksheet.body.items[record.index])
                itemCopy.options = record.options
                commit('UPDATE_ITEM', { index: record.index, item: itemCopy })
            },
            pushMultipleChoiceOption({ commit, state }, index) {
                let itemCopy = copyObject(state.worksheet.body.items[index])
                itemCopy.options.push({
                    answer: '',
                    correct: 0,
                })
                commit('UPDATE_ITEM', { index: index, item: itemCopy })
            },
            updateMultipleChoiceOption({ commit, state }, record) {
                let itemCopy = copyObject(state.worksheet.body.items[record.index])
                itemCopy.options[record.optionIndex].answer = record.value

                commit('UPDATE_ITEM', { index: record.index, item: itemCopy })
            },
            removeMultipleChoiceOption({ state, commit }, record) {
                let itemCopy = copyObject(state.worksheet.body.items[record.index])
                itemCopy.options.splice(record.optionIndex, 1)
                commit('UPDATE_ITEM', { index: record.index, item: itemCopy })
            },
            resetWorksheetStyle({ commit }) {
                commit('RESET_WORKSHEET_SETUP')
            },
            setMatchingColumnFormat({ commit, getters, dispatch }, payloads) {
                commit('FORMAT_MATCHING_NUMBERS', { getters, ...payloads })
            },
            setSpecialChoiceAnswerFormat({ commit, getters, dispatch }, payloads) {
                commit('FORMAT_SPECIAL_CHOICE_NUMBERS', { getters, ...payloads })
            },
            toggleItemVisibility({ commit }, { index, hide }) {
                commit('TOGGLE_ITEM_VISIBILITY', { index, hide })
            },
        }
    }

    mutations() {
        return {
            ...super.mutations(),
            SET_WORKSHEET_VALUES(state, payloads) {
                Object.keys(payloads).forEach((value) => {
                    if (value === 'body') {
                        const worksheet_body = payloads[value]
                        state.worksheet.body.items = worksheet_body.items.map((item) => Activity.parseFromObject(item))
                        state.worksheet.body.style = worksheet_body.style
                    } else if (value === 'images') {
                        if (payloads[value]) {
                            state.worksheet.images = payloads[value]
                            state.worksheet.images.forEach((image) => {
                                if (!image.ratio) image.ratio = image.width / image.height
                            })
                        }
                    } else {
                        state.worksheet[value] = payloads[value]
                    }
                })
            },
            SET_WORKSHEET_BODY(state, payloads) {
                if (state.worksheet.body) {
                    Object.keys(payloads).forEach((value) => {
                        state.worksheet.body[value] = payloads[value]
                    })
                }
            },
            SET_WORKSHEET_STYLE(state, payloads) {
                if (state.worksheet.body) {
                    Object.keys(payloads).forEach((value) => {
                        state.worksheet.body.style[value] = payloads[value]
                    })
                }
            },
            SET_WORKSHEET_WORDBANK_VALUES(state, payloads) {
                if (state.worksheet.body?.wordbank) {
                    Object.keys(payloads).forEach((value) => {
                        state.worksheet.body.wordbank[value] = payloads[value]
                    })
                }
            },
            SET_WORKSHEET_WORDBANK_STYLE(state, payloads) {
                if (state.worksheet.body?.wordbank) {
                    Object.keys(payloads).forEach((value) => {
                        state.worksheet.body.wordbank.style[value] = payloads[value]
                    })
                }
            },
            SET_WORKSHEET_SETTING(state, payloads) {
                if (state.worksheet.body?.setting) {
                    Object.keys(payloads).forEach((value) => {
                        state.worksheet.body.setting[value] = payloads[value]
                    })
                }
            },

            RESET_WORKSHEET_SETUP(state) {
                state.worksheet = {
                    body: {
                        style: new Style(),
                        items: [],
                    },
                    images: [],
                    inline_images: [],
                }
            },
            FORMAT_MATCHING_NUMBERS(state, { index, at, format }) {
                const documentItems = state.worksheet.body.items
                const item = documentItems[index]

                Vue.set(item.data.settings, 'display_index', true)

                if (at === 'left') {
                    Vue.set(item.data.settings, 'left_column_format', parseInt(format))
                    return
                }

                Vue.set(item.data.settings, 'right_column_format', parseInt(format))
            },
            FORMAT_SPECIAL_CHOICE_NUMBERS(state, { index, format }) {
                const documentItems = state.worksheet.body.items
                const item = documentItems[index]
                Vue.set(item.data, 'settings', { format: parseInt(format) })
            },
            TOGGLE_ITEM_VISIBILITY(state, { index, hide }) {
                state.worksheet.body.items[index].hide = hide
            },
        }
    }

    getModules() {
        const fakeAnswersModule = new FakeAnswersModule().getModules()
        return mergeDeep(super.getModules(), fakeAnswersModule)
    }
}

import BaseModule from '../base'
import { zoomLevels } from '../../../objects/Document'
import { isNumber } from 'lodash'

export const toggleWorkspaceScroll = (disable) => {
    let container = document.getElementById('workspaceContainer')

    let overflowState = 'visible'

    if (disable) {
        overflowState = 'hidden'
    }
    container.style.overflowX = overflowState
}

//Check if document display area is smaller than bounding container
export const documentIsSmallerThanWorkspace = (ratio) => {
    let documentWidth = document.getElementById('workspace').offsetWidth * ratio

    let container = document.getElementById('workspaceContainer')
    let innerWidth = container.offsetWidth
    let leftPadding = parseFloat(window.getComputedStyle(container).paddingLeft)
    let rightPadding = parseFloat(window.getComputedStyle(container).paddingRight)

    let widthWithoutPadding = innerWidth - (leftPadding + rightPadding)

    return documentWidth + rightPadding < widthWithoutPadding
}

export default class ZoomModule extends BaseModule {
    state() {
        return {
            ...super.state(),
            zoom: 1,
            ratio: 1,
            zoomLevels: zoomLevels,
            manuallyResizedUp: false,
        }
    }
    getters() {
        return {
            ...super.getters(),
            getScaleRatio: (state) => {
                return state.ratio
            },
            manuallyResizedUp: (state) => {
                return state.manuallyResizedUp
            },
            getZoomLevels: (state) => {
                return state.zoomLevels.filter((level) => level != 'Fit')
            },
        }
    }
    actions() {
        return {
            ...super.actions(),
            zoomDir({ state, dispatch }, event) {
                let { value } = event.target
                const dir = event.direction
                let index = state.zoomLevels.indexOf(value)

                let newValue
                if (index < 0) {
                    //if they have a custom zoom reset it
                    if (dir == 'up') {
                        value = 'Fit'
                        index = 0
                    } else {
                        value = '2'
                        index = state.zoomLevels.length - 1
                    }
                }

                if (dir == 'up') {
                    if (index + 1 == state.zoomLevels.length) {
                        newValue = state.zoomLevels[0]
                    } else {
                        newValue = state.zoomLevels[index + 1]
                    }
                } else {
                    if (index == 0) {
                        newValue = state.zoomLevels[state.zoomLevels.length - 1]
                    } else {
                        newValue = state.zoomLevels[index - 1]
                    }
                }
                dispatch('setZoom', newValue)
            },
            setZoom({ commit, dispatch, state }, event) {
                let value = event

                if (event.target) {
                    //if this is an event
                    value = event.target.value
                }

                let parsed = parseInt(value)

                if (value.toLowerCase() == 'fit') {
                    value = 'Fit'
                    dispatch('getScaleRatio').then((ratio) => {
                        if (ratio > 1 || documentIsSmallerThanWorkspace(ratio)) {
                            commit('SET_MANUAL_RESIZE', true)
                        }
                    })
                } else if (!isNaN(parsed) && parsed >= 25 && parsed <= 300) {
                    value = parsed / 100
                    if (parsed > 100) {
                        commit('SET_MANUAL_RESIZE', true)
                    }
                } else if (!isNaN(parsed) && parsed < 25) {
                    parsed = 25
                    value = parsed / 100
                    document.getElementById('zoomID').value = parsed.toString() + '%'
                } else if (!isNaN(parsed) && parsed > 300) {
                    parsed = 300
                    value = parsed / 100
                    document.getElementById('zoomID').value = parsed.toString() + '%'
                } else {
                    event.preventDefault()
                    event.target.value = state.zoom == 'Fit' ? 'Fit' : state.zoom * 100 + '%'
                    return // do not complete the action
                }

                commit('SET_DOCUMENT_ZOOM', value)
                dispatch('scaleDocument', true)
            },
            scaleDocument({ getters, dispatch, state }, isZoom = false) {
                let workspace = document.getElementById('workspace')
                if (!workspace) return
                dispatch('getScaleRatio').then((ratio) => {
                    workspace.style.transform = 'scale(' + ratio + ')'

                    let zoomContainer = document.getElementById('zoomContainer')

                    if (zoomContainer) {
                        zoomContainer.style.width = workspace.clientWidth + 'px'
                    }

                    let documentContainer = document.getElementById('document-container')

                    if (documentContainer && workspace.clientHeight > 0 && isZoom) {
                        documentContainer.style.height = `${workspace.clientHeight * ratio}px`
                    }
                    //Fit does not need predefined width
                    if (state.zoom == 'Fit' || (isNumber(state.zoom) && state.zoom < 1)) {
                        documentContainer.style.width = 'auto'
                    } else {
                        documentContainer.style.width = '8.5in'
                    }
                })
            },
            checkScroll({ dispatch, state }) {
                dispatch('getScaleRatio').then((ratio) => {
                    toggleWorkspaceScroll(
                        state.zoom == 'Fit' || (isNumber(ratio) && ratio < 1 && documentIsSmallerThanWorkspace(ratio)),
                    )
                })
            },
            getScaleRatio({ state }) {
                let ratio
                const workspaceElm = document.getElementById('workspace')
                if (!workspaceElm) {
                    return 1
                }

                if (state.zoom == 'Fit') {
                    const documentScale = workspaceElm.clientWidth
                    let availableWidth = 0

                    // Get the computed style of the workspace container element
                    let workspaceContainer = document.getElementById('workspaceContainer')
                    let workspaceStyles = window.getComputedStyle(workspaceContainer)
                    let paddingLeft = parseFloat(workspaceStyles.getPropertyValue('padding-left'))
                    let paddingRight = parseFloat(workspaceStyles.getPropertyValue('padding-right'))
                    let scrollbarBuffer = 4

                    // Get the sidebar container
                    let sidebarContainer = document.getElementById('sidebarContainer')

                    availableWidth =
                        window.innerWidth - (sidebarContainer.offsetWidth + paddingLeft + paddingRight + scrollbarBuffer)

                    //max 4 min .2
                    ratio = Math.min(Math.max(1 / (documentScale / availableWidth), 0.2), 4)
                    //round to 2 decimals
                    ratio = (ratio * 100) / 100
                } else {
                    ratio = state.zoom
                }
                state.ratio = ratio
                return ratio
            },
            getCurrentZoom: (state) => {
                return state.zoom
            },
            setManualResize: ({ commit }, value) => {
                //true / false value
                commit('SET_MANUAL_RESIZE', value)
            },
        }
    }
    mutations() {
        return {
            ...super.mutations(),
            SET_VALUE(state, values) {
                Object.keys(values).forEach((value) => {
                    state[value] = values[value]
                })
            },
            SET_MANUAL_RESIZE(state, value) {
                state.manuallyResizedUp = value
            },
            SET_DOCUMENT_ZOOM(state, payloads) {
                state.zoom = payloads
            },
        }
    }
}
